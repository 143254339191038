import React, { FC } from 'react'
import { Input as ANTDInput } from 'antd'
import { InputProps } from 'antd/lib/input/Input'
import { InputStyle } from 'src/@legacy/@core/style/styled/antd/Input.style'
import { PasswordProps } from 'antd/lib/input/Password'
import { TextAreaProps } from 'antd/lib/input/TextArea'
import { SearchProps } from 'antd/lib/input'

const Input: FC<InputProps & { enterkeyhint?: string }> & {
  Password: FC<PasswordProps>
  TextArea?: FC<TextAreaProps>
  Search?: FC<SearchProps>
} = (props) => (
  <>
    <style jsx global>
      {InputStyle}
    </style>
    <ANTDInput {...props} />
  </>
)
Input.Password = (props) => (
  <>
    <style jsx global>
      {InputStyle}
    </style>
    <ANTDInput.Password {...props} />
  </>
)
Input.TextArea = (props) => (
  <>
    <style jsx global>
      {InputStyle}
    </style>
    <ANTDInput.TextArea {...props} />
  </>
)
Input.Search = (props) => (
  <>
    <style jsx global>
      {InputStyle}
    </style>
    <ANTDInput.Search {...props} />
  </>
)

export { Input }
